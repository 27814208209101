import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const CardSoonSection = () => {

    const {width} = useWindowSize();

    return (
        <CardSoonSectionContainer>
        <CardSoonSectionItem style={{
            'width': '100%',
            'height': width && width > LAPTOP_MOBILE_POINTER ? '408px' : '100%',
            'background': 'var(--background-main-grey)',
        }}>
            <CardSoonSectionItemInfo
                style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '50%' : '100%'}}>
                <CardSoonSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>Скоро — платежные карты</CardSoonSectionItemTitle>
                <CardSoonSectionItemDescription >С платежной картой Сам.Кард вы забудете о переводах и комиссиях — приложите пластиковую или электронную карту к терминалу и оплатите покупку балансом Кошелька</CardSoonSectionItemDescription>
            </CardSoonSectionItemInfo>

            <CardSoonSectionItemImage style={{
                'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'block',
                'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                'right': width && width > LAPTOP_MOBILE_POINTER ? '48px' : '0',
                'width': width && width > LAPTOP_MOBILE_POINTER ? '442px' : 'auto',
                'top': width && width > LAPTOP_MOBILE_POINTER ? '47px' : '0'
            }}>
                <StaticImage
                    src="../../../images/wallet/CardSoonSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </CardSoonSectionItemImage>
        </CardSoonSectionItem>
        </CardSoonSectionContainer>
    )
};

const CardSoonSectionContainer = styled.section`
    margin: 160px 0px 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px 0 80px;
    }
    @media (max-width: 609px) {
        margin: 80px -19px 80px;
    }
`;

const CardSoonSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 96px;
    padding-right: 96px;
    border-radius: 20px;
    padding-bottom: 40px;
    overflow: hidden;
    position: relative;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding: 20px;
    }
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const CardSoonSectionItemInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`;

const CardSoonSectionItemTitle = styled(TypographyUI.H2)`
    margin-bottom: 20px;
`;

const CardSoonSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;

const CardSoonSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;
