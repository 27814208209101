import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const AnalyzeWalletSection = () => {

    const {width} = useWindowSize();

    return (
        <AnalyzeWalletSectionContainer>
        <AnalyzeWalletSectionItem style={{
            'width': '100%',
            'height': width && width > LAPTOP_MOBILE_POINTER ? '408px' : '100%',
            'background': 'var(--background-second)',
        }}>
            <AnalyzeWalletSectionItemInfo
                style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '44%' : '100%'}}>
                <AnalyzeWalletSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>Все данные по продажам в одном месте</AnalyzeWalletSectionItemTitle>
                <AnalyzeWalletSectionItemDescription >С помощью Кошелька вы можете отслеживать и анализировать свои доходы: сколько услуг или товаров продали, от кого получили деньги и какие каналы продаж наиболее эффективны</AnalyzeWalletSectionItemDescription>
            </AnalyzeWalletSectionItemInfo>

            <AnalyzeWalletSectionItemImage style={{
                'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'block',
                'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                'right': width && width > LAPTOP_MOBILE_POINTER ? '192px' : '0',
                'width': width && width > LAPTOP_MOBILE_POINTER ? '261px' : 'auto',
                'top': width && width > LAPTOP_MOBILE_POINTER ? '47px' : '0'
            }}>
                <StaticImage
                    src="../../../images/wallet/AnalyzeWalletSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </AnalyzeWalletSectionItemImage>
        </AnalyzeWalletSectionItem>
        </AnalyzeWalletSectionContainer>
    )
};

const AnalyzeWalletSectionContainer = styled.section`
    margin: 160px 0px 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px 0 80px;
    }
    @media (max-width: 609px) {
        margin: 80px -19px 80px;
    }
`;

const AnalyzeWalletSectionItem = styled.div`
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding: 20px 20px 0;
    }
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const AnalyzeWalletSectionItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const AnalyzeWalletSectionItemTitle = styled(TypographyUI.H3)`
    margin-bottom: 16px;
`;

const AnalyzeWalletSectionItemDescription = styled(TypographyUI.Body1Regular)`

`;

const AnalyzeWalletSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 35%;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;
