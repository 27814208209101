import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import React from "react";
import {useWindowSize} from "@tools/hooks";
import styled from "styled-components";

import num1Img from "@images/wallet/GetStartSectionImage1.svg";
import num2Img from "@images/wallet/GetStartSectionImage2.svg";
import num3Img from "@images/wallet/GetStartSectionImage3.svg";
import {AdaptiveLinkCt} from "@components";

export const GetStartSection = () => {

    const {width} = useWindowSize();

    return (
        <GetStartSectionContainer>
            <GetStartSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Кошелек самозанятого: как открыть и начать работать
            </GetStartSectionTitle>

            <GetStartSectionItems>
                <GetStartSectionItem>
                    <GetStartSectionItemImg
                        src={num1Img}
                        alt="num-1"
                    />
                    <GetStartSectionItemTitle>
                        Введите телефон и задайте пароль
                    </GetStartSectionItemTitle>
                    <GetStartSectionItemDescription>
                        Так вы создадите учетную запись физического лица и сможете принимать деньги от знакомых
                    </GetStartSectionItemDescription>
                </GetStartSectionItem>

                <GetStartSectionItem>
                    <GetStartSectionItemImg
                        src={num2Img}
                        alt="num-2"
                    />
                    <GetStartSectionItemTitle>
                        Укажите ИНН
                    </GetStartSectionItemTitle>
                    <GetStartSectionItemDescription>
                        Кошелек направит запрос в приложение «Мой налог», чтобы проверить ваш статус самозанятого
                    </GetStartSectionItemDescription>
                </GetStartSectionItem>

                <GetStartSectionItem>
                    <GetStartSectionItemImg
                        src={num3Img}
                        alt="num-3"
                    />
                    <GetStartSectionItemTitle>
                        Зайдите в «Мой налог»
                    </GetStartSectionItemTitle>
                    <GetStartSectionItemDescription>
                        Предоставьте Кошельку права в «Мой налог», чтобы получить доступ ко всем функциям платформы
                    </GetStartSectionItemDescription>
                </GetStartSectionItem>

            </GetStartSectionItems>

            <OpenWalletBtn>
                <AdaptiveLinkCt to="https://pro.selfwork.ru">
                    <ButtonSd>Открыть кошелек</ButtonSd>
                </AdaptiveLinkCt>
            </OpenWalletBtn>

        </GetStartSectionContainer>
    )
};

const GetStartSectionContainer = styled.section`
    margin: 0px 0 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px 0 100px;
    }
`;

const GetStartSectionTitle = styled(TypographyUI.H2)`
    text-align: center;
    margin: 0 auto 75px;
    max-width: 820px;
`

const GetStartSectionItems = styled.div`
    display: flex;
    gap: 32px;

    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const GetStartSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 360px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const GetStartSectionItemImg = styled.img`
    width: 72px;
    height: 72px;
    margin-bottom: 32px;
`;

const GetStartSectionItemTitle = styled(TypographyUI.H4Bold)`
    padding-bottom: 12px;
`;

const GetStartSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;

const OpenWalletBtn= styled.div`
    margin: 60px auto 0;
    max-width: 820px;
    text-align: center;
`;

const ButtonSd = styled(ButtonUI.Primary)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 240px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 100%;
  }
`;
