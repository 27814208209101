import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const WhyWalletSection = () => {

    const {width} = useWindowSize();

    return (
        <WhyWalletSectionContainer>

            <WhyWalletSectionItems>

                <WhyWalletSectionImage>
                    <StaticImage style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '557px' : '100%'}}
                                 src="../../../images/wallet/WhyWalletSectionImage.png"
                                 loading="eager"
                                 placeholder="none"
                                 formats={["auto", "webp"]}
                                 layout="constrained"
                                 objectFit="contain"
                                 alt=""
                    />
                </WhyWalletSectionImage>

                <WhyWalletSectionContent>
                    <WhyWalletSectionContentTitle>Получайте доход и не думайте о платежных документах</WhyWalletSectionContentTitle>
                    <WhyWalletSectionDescription>Кошелек от Самозанятые.рф — это сервис-помощник для всех, кто хочет автоматизировать прием платежей и следить за финансами</WhyWalletSectionDescription>
                </WhyWalletSectionContent>

            </WhyWalletSectionItems>

        </WhyWalletSectionContainer>
    )
};

const WhyWalletSectionContainer = styled.section`
    display: flex;
    margin-top: 120px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    background: var(--background-main-grey);
    height: 583px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 40px;
        height: 100%;
        padding: 30px 40px;
    }
`;

const WhyWalletSectionTitle = styled(TypographyUI.H3)`
    margin-bottom: 24px;
`;

const WhyWalletSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


const WhyWalletSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        max-width: 1204px;
    }
    @media (min-width: 609px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 609px;
    }
    @media (max-width: 609px) {
    }
`;

const WhyWalletSectionImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
    }
`;

const WhyWalletSectionContent = styled.div`
    width: 490px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


const WhyWalletSectionContentTitle = styled(TypographyUI.H3)`

`;

const WhyWalletSectionContentDescription = styled(TypographyUI.Body1Regular)`

`;
